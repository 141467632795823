<!-- 扫码登陆 -->
<template>
  <div>
    <div style="height: 3rem"></div>
    <div class="box_css">
      <div v-if="ssty == 1">
        <div class="title_css">扫码登陆</div>
        <div class="erweima_css">
          <img :src="QRCode" />
        </div>
      </div>
      <div v-else-if="ssty == 2">
        <div class="title_css">账号登录</div>
        <div class="erweima_css">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="60px"
            class="demo-ruleForm"
          >
            <el-form-item label="账号" prop="loginAccount">
              <el-input
                v-model="ruleForm.loginAccount"
                placeholder="请输入账号"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="loginPwd">
              <el-input
                show-password
                v-model="ruleForm.loginPwd"
                placeholder="请输入密码"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="remember">
            <el-checkbox size="medium" v-model="rememberPsw"
              >记住密码</el-checkbox
            >
            <div class="changeText" @click="changeAccount()">
              已有账号?<span class="changeMsg">点击切换</span>
            </div>
          </div>
        </div>
        <div class="LogOut_css" @click="login()">确认登陆</div>
      </div>
      <!-- 切换账号 -->
      <div v-else-if="ssty == 3">
        <div class="title_css">轻触以切换账号</div>
        <div class="change_css" v-if="userList.length > 0">
          <div
            class="userItemBox"
            v-for="(item, index) in userList"
            :key="item.UserID"
            @click="chooseUser(item)"
          >
            <avatar
              style="margin-left: 5px;"
              :imagesrc="item.photo"
              fontSize="3"
              BRnumber="50"
            />
            <div class="userName_css">{{ item.userName }}</div>
            <div class="loginAccount_css">({{ item.loginAccount }})</div>
            <!-- 当前账号 -->
            <!-- <img class="nowAccount" v-if="item.UserID == nowUserId" src="@/assets/now.png"></img> -->
          </div>
        </div>
        <div class="noUser" v-else>暂无缓存用户</div>
      </div>
      <div class="title_css_blue">
        <span v-if="ssty == 1" @click="ssty = 2">账号密码登陆</span>
        <span v-if="ssty == 2" @click="ssty = 1">扫码登陆</span>
        <span v-if="ssty == 3" @click="ssty = 2">账号密码登录</span>
      </div>
    </div>
  </div>
</template>
<script>
import { LoginMa, Login } from '@/api/index.js'
import md5 from '@/MD5/md5.js'
let timerLoginMa = null
let timerLoginMa5 = null
export default {
  data() {
    return {
      rememberPsw: true, //是否记住密码
      num16: '',
      QRCode: '',
      ssty: 1, //登录方式 1账号登录 2扫码 3切换账号
      show: false,
      ruleForm: {
        loginAccount: '',
        loginPwd: '',
      },
      userList: [], //缓存的用户信息
    }
  },
  created() {
    this.num16 = this.getmm()
    this.getLoginMa()
    timerLoginMa = setInterval(() => {
      this.getLoginMa()
    }, 1000 * 2)
    this.FiveMinutes()
  },
  beforeDestroy() {
    if (timerLoginMa) {
      clearInterval(timerLoginMa)
      timerLoginMa = null
    }
    if (timerLoginMa5) {
      clearInterval(timerLoginMa5)
      timerLoginMa5 = null
    }
  },
  methods: {
    getmm(num = 16) {
      var amm = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'm',
        'n',
        'p',
        'Q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
      ]
      var tmp = Math.floor(Math.random() * num)
      var s = tmp
      s = s + amm[tmp]
      for (let i = 0; i < Math.floor(num / 2) - 1; i++) {
        tmp = Math.floor(Math.random() * 26)
        s = s + String.fromCharCode(65 + tmp)
      }
      for (let i = 0; i < num - Math.floor(num / 2) - 1; i++) {
        tmp = Math.floor(Math.random() * 26)
        s = s + String.fromCharCode(97 + tmp)
      }
      return s
    },
    //切换用户
    chooseUser(obj) {
      Login({
        loginAccount: obj.loginAccount,
        loginPwd: obj.loginPwd,
      })
        .then((res) => {
          if (res.data.GathererID == 3) {
            localStorage.setItem('smartCampusToken', res.data.Token)
            localStorage.setItem('userInformation', JSON.stringify(res.data))
            this.$bus.$emit('getItem')
            this.$message.success('登录成功')
            setTimeout(() => {
              this.$router.push({ path: '/' })
            }, 300)
          } else {
            this.$message.warning('请教师账号登录')
          }
        })
        .catch((err) => {
          this.$message.warning('登录失败')
        })
    },
    // 获取二维码
    getLoginMa() {
      // localStorage.setItem('smartCampusToken','lg1enUVcFv2Cp2MzQ8dxPpzf+W+dU/QGe31/TafrRzAsutlMv5QeH6jlgYzLVZ/y')
      // localStorage.setItem('userInformation',JSON.stringify({
      //   userName:'郭春雷'
      // }))
      // return
      LoginMa(this.num16, localStorage.getItem('classID')).then((res) => {
        if (this.QRCode != res.data.photo) {
          this.QRCode = res.data.photo
        }
        if (res.data.UserID) {
          if (timerLoginMa) {
            clearInterval(timerLoginMa)
            timerLoginMa = null
          }
          localStorage.setItem('smartCampusToken', res.data.Token)
          localStorage.setItem('userInformation', JSON.stringify(res.data))
          this.$bus.$emit('getItem')
          this.$message.success('登录成功')
          this.$router.push({ path: '/' })
        }
      })
    },
    FiveMinutes() {
      timerLoginMa5 = setInterval(() => {
        this.num16 = this.getmm()
        if (timerLoginMa) {
          clearInterval(timerLoginMa)
          timerLoginMa = null
        }
        timerLoginMa = setInterval(() => {
          this.getLoginMa()
        }, 1000 * 2)
      }, 1000 * 60 * 5)
    },
    //切换登录账号
    changeAccount() {
      this.ssty = 3
      this.userList = JSON.parse(localStorage.getItem('userList')) || []
    },
    // 密码登录接口
    login() {
      if (this.show) {
        this.$message.warning('登陆中，请稍等')
        return
      }
      if (this.ruleForm.loginAccount == '') {
        this.$message.warning('请填写账号')
        return
      }
      if (this.ruleForm.loginPwd == '') {
        this.$message.warning('请填写密码')
        return
      }
      this.show = true
      let hash = md5(this.ruleForm.loginPwd).toLocaleUpperCase()
      Login({
        loginAccount: this.ruleForm.loginAccount,
        loginPwd: hash,
      })
        .then((res) => {
          if (res.data.GathererID == 3) {
            localStorage.setItem('smartCampusToken', res.data.Token)
            localStorage.setItem('userInformation', JSON.stringify(res.data))
            //记住密码则缓存账号密码
            if (this.rememberPsw) {
              let obj = {
                loginAccount: this.ruleForm.loginAccount,
                loginPwd: hash,
                userName: res.data.userName,
                UserID: res.data.UserID,
                photo: res.data.photo,
              }
              //缓存用户数组
              let cacheUserList =
                JSON.parse(localStorage.getItem('userList')) || []
              //本地没有缓存直接存
              if (cacheUserList.length <= 0) {
                cacheUserList.push(obj)
                localStorage.setItem('userList', JSON.stringify(cacheUserList))
              } else {
                //是否已经存过缓存(存过则更新 避免密码更新)
                let isHasIndex = cacheUserList.findIndex(
                  (Item) => Item.UserID == res.data.UserID
                )
                if (isHasIndex !== -1) {
                  cacheUserList.splice(isHasIndex, 1)
                  cacheUserList.push(obj)
                  localStorage.setItem(
                    'userList',
                    JSON.stringify(cacheUserList)
                  )
                } else {
                  cacheUserList.push(obj)
                  localStorage.setItem(
                    'userList',
                    JSON.stringify(cacheUserList)
                  )
                }
              }
            }
            this.$bus.$emit('getItem')
            this.$message.success('登录成功')
            this.$router.push({ path: '/' })
          } else {
            this.$message.warning('请教师账号登录')
            this.ruleForm.loginAccount = ''
            this.ruleForm.loginPwd = ''
          }
          this.show = false
        })
        .catch((err) => {
          this.show = false
        })
    },
  },
}
</script>
<style scoped>
.box_css {
  width: 1200px;
  height: calc(100vh - 230px);
  margin: 0 auto;
  background-color: #ffffffda;
  border-radius: 25px;
  position: relative;
}
.title_css {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding: 40px 0;
}
.change_css {
  height: 300px;
  width: 500px;
  margin: 0 auto;
  overflow-y: auto;
}
.noUser {
  margin: 0 auto;
  height: 300px;
  width: 500px;
  color: #909399;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nowAccount {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0px;
}
.userItemBox {
  position: relative;
  width: 500px;
  min-height: 60px;
  height: auto;
  padding: 0 5px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.loginAccount_css {
  font-size: 16px;
  color: #909399;
  margin-left: 10px;
}
.userName_css {
  font-size: 25px;
  margin-left: 15px;
  letter-spacing: 2px;
}
.erweima_css {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 50px;
  width: fit-content;
}
.erweima_css > img {
  width: 300px;
  height: 300px;
}
.title_css_blue {
  position: absolute;
  bottom: 18px;
  right: 18px;
  color: blue;
  z-index: 10;
  font-size: 22px;
  cursor: pointer;
  user-select: none;
}
.remember {
  display: flex;
  width: 450px;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
}
.changeText {
  color: #909399;
}
.changeMsg {
  color: #409eff;
}
.LogOut_css {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto 0;
  height: 60px;
  width: 450px;
  background-color: rgb(71, 71, 255);
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
  cursor: pointer;
}
/deep/ .el-input__inner {
  width: 400px;
  height: 55px;
  line-height: 55px;
}
/deep/ .el-form-item__label {
  font-size: 20px;
  line-height: 55px;
}
/deep/ .el-input {
  font-size: 18px;
}
</style>
